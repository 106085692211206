<template>
  <ul class="tabs">
    <router-link
      v-for="(tab, index) in tabs"
      :key="index"
      tag="li"
      class="link-container"
      :to="tab.route"
    >
      <a class="link" @click="changeSort($event, tab.sortVariableName)">
        {{ tab.title }}
        <img
          v-if="tab.sortVariableName"
          src="@/assets/images/icons/table/sort-arrow.svg"
          class="arrow"
          :class="{
            asc: !getSortState(tab.sortVariableName),
          }"
        >
      </a>
    </router-link>
  </ul>
</template>

<script>
import lodash from 'lodash';

export default {
  components: {},
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      current: null,
    };
  },
  methods: {
    changeSort(event, sortVariableName) {
      if (
        sortVariableName
        && (event.target.parentElement.classList.contains('router-link-active')
          || event.target.parentElement.parentElement.classList.contains(
            'router-link-active',
          ))
      ) {
        this.$store.commit(
          `pageSpecificData/${lodash.camelCase(`toggle${sortVariableName}`)}`,
        );
      }
    },
    getSortState(sortVariableName) {
      return this.$store.getters[
        `pageSpecificData/${lodash.camelCase(sortVariableName)}`
      ];
    },
  },
};
</script>

<style lang="scss">
.tabs {
  padding: 0 5px 0 5px;
  margin: 0 20px 0 20px;
  background-color: $light-gray;
  display: table;
  &:before,
  &:after {
    display: table;
    content: ' ';
    clear: both;
  }
  .link-container {
    height: 50px;
    display: block;
    background-color: $light-gray;
    margin-top: 5px;
    margin-bottom: -1px;
    float: left;
    &.router-link-active {
      color: $black;
      background-color: #fff;
      &:before {
        opacity: 0;
      }
      & + li:before {
        opacity: 0;
      }
    }
    &:not(.router-link-active) > a {
      &:hover {
        background-color: #eee;
      }
      &:focus {
        text-decoration: none;
        background-color: #fff;
      }
    }
    &:not(:first-child):before {
      content: '|';
      font-size: 25px;
      color: #d3d3d3;
    }
  }

  .link {
    display: inline-block;
    padding: 14px 20px 17px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 18.75px;
    height: 50px;
    text-decoration: none;
    color: $black;
  }

  .arrow {
    float: right;
    margin-top: 8px;
    margin-left: 5px;

    &.asc {
      transform: rotate(180deg);
    }
    &.hidden {
      visibility: hidden;
    }
  }
}
</style>
